import React from "react"
import { useWindowScroll } from "react-use"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import SVGcoffee from "../../assets/svg/chacha/coffee.svg"
import SVGtea from "../../assets/svg/chacha/tea.svg"
import SVGjuice from "../../assets/svg/chacha/juice.svg"
import SVGcocktails from "../../assets/svg/chacha/cocktails.svg"
import SVGwine from "../../assets/svg/chacha/wine.svg"
import SVGbeer from "../../assets/svg/chacha/beer.svg"
import SVGdigestif from "../../assets/svg/chacha/digestif.svg"
import SVGentrees from "../../assets/svg/chacha/entrees.svg"
import SVGmains from "../../assets/svg/chacha/mains.svg"
import SVGsharing from "../../assets/svg/chacha/sharing.svg"
import SVGdesserts from "../../assets/svg/chacha/desserts.svg"
import SVGcheese from "../../assets/svg/chacha/cheese.svg"

import IntroVideo from "../../assets/images/chacha/cha-1A.mp4"
import IphoneVideo from "../../assets/images/chacha/chacha.mp4"

import "./template-styles.scss"
import "./cha-cha.scss"
import Img from "../../assets/images/cha-cha-images"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

const purple = "#7D476B"
const pink = "#DB5566"
const beige = "#E6D8BF"
const black = "#212931"
const white = "#ffffff"

const ChaChaProject = () => {
  ProjectAnimations()
  return (
    <div className="project cha-cha">
      <SEO
        title="Cha Cha"
        description="A brand and interior fit-out that gave a London café its buzz.
      Brand strategy, name, logo, design platform, interior fit-out, collateral"
      />

      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <video
            src={IntroVideo}
            autoPlay
            playsInline
            muted
            loop
            className="h-full object-cover"
          ></video>
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: beige }}
        >
          <div>
            <h1>Cha Cha Café</h1>
            <p>
              Partnering an awesome cappuccino with a compelling look and feel
              gets patrons through the doors. The new owners of what would be a
              lively little coffee joint threw themselves into their new venture
              fearlessly. They brought the energy and the cha-cha. We brought it
              all to life. <br />
              <br />
              Brand strategy, name, logo, design platform, interior fit-out,
              collateral.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-1">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="cha4" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: beige, color: black }}
        >
          <div>
            <p>
              The rhythm of those three little cha-cha-cha steps shaped a fun,
              exciting visual and tempo for the logo and design platform. It
              dressed the cafe, the collateral and set the vibe.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-2">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="cha3" className="h-full" />
        </div>
      </Slide>

      {/* <Slide className="section-3">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="cha2" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: beige }}
        >
          <div>
            <p>{introParagraph}</p>
          </div>
        </div>
      </Slide> */}
      <Slide className="section-3">
        <div className="iphone  panel--w-half panel--h-screen panel--image  panel--image--full">
          <div className="screen-container__inner-container">
            <Img src="cha2" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={IphoneVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: beige }}
        >
          <div>
            <div>
              <p>
                A Cha Cha flavoured app made it easy for customers to sort their
                fix on the move.
              </p>
            </div>
          </div>
        </div>
      </Slide>

      <Slide className="section-4">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha5" className="h-full" />
        </div>
      </Slide>

      <div className="section-5 carousel-container section-wrapper">
        <div className="panel--w-full pin-once-scrolled">
          <div
            className="panel--w-half panel--h-screen centered-content "
            style={{ backgroundColor: pink, color: white }}
          >
            <div className="panel--text">
              <p>
                An icon set was designed around the three core shapes and
                colours of the logomark, building a consolidated brand style.
              </p>
            </div>
          </div>
        </div>

        <div className="items-container-parent pin-for-400 pin-fully-scrolled flex flex-wrap">
          <Spacer />
          <div
            className="items-container panel--w-half flex flex-col"
            style={{ backgroundColor: beige }}
          >
            {/* <Img src="measure" className="item" />
            <Img src="elevate" className="item" />
            <Img src="focus" className="item" />
            <Img src="realign" className="item" /> */}
            <SVGcoffee className="item" />
            <SVGtea className="item" />
            <SVGjuice className="item" />
            <SVGcocktails className="item" />
            <SVGwine className="item" />
            <SVGbeer className="item" />
            <SVGdigestif className="item" />
            <SVGentrees className="item" />
            <SVGmains className="item" />
            <SVGsharing className="item" />
            <SVGdesserts className="item" />
            <SVGcheese className="item" />
          </div>
        </div>
      </div>
      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha6" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha7" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-7">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content"
          style={{ backgroundColor: beige, color: black }}
        >
          <div className="panel--text">
            <p>
              A menu set explored the usage of the brand shapes and colours
              through a repeating pattern of the logomark letterforms.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-8">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha8" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-9">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha9" />
        </div>
      </Slide>

      <div className="section-10 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: purple, color: white }}
        >
          <div className="panel--text">
            <p>
              Cha Cha roast their own coffee beans to create a truly unique
              flavour, and customers can purchase freshly roasted beans to brew
              at home. Coffee bags were branded with the Cha Cha pattern,
              wrapped and ready to carry the brand beyond the doors.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-11">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha10" />
        </div>
      </Slide>

      <Slide className="section-12">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="cha11" />
        </div>
      </Slide>
      <Slide className="section-13">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div className="panel--text">
            <p>
              A full package brand delivery designed to get Cha Cha humming and
              keep customers coming back for more.
              <br />
              <br />
              <TransitionLink
                to="/projects/au-natural/"
                color="#9fc979"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default ChaChaProject
